import React from 'react';
import { kebabCase } from 'lodash';
import { graphql, useStaticQuery } from 'gatsby';
import LinkButton, { Types, Sizes } from '../../components/LinkButton';

export default () => {
  const data = useStaticQuery(graphql`
    query tagButtons {
      site {
        siteMetadata {
          title
        }
      }
      allMarkdownRemark(
        limit: 1000
        filter: {
          frontmatter: {
            templateKey: { eq: "blog-post" }
            featuredpost: { eq: true }
          }
        }
      ) {
        group(field: frontmatter___tags) {
          fieldValue
          totalCount
        }
      }
    }
  `);

  return (
    <>
      {data.allMarkdownRemark.group.map((item, i) => {
        return (
          <LinkButton
            key={i}
            to={`/tags/${kebabCase(item.fieldValue)}/`}
            label={item.fieldValue}
            size={Sizes.middle}
            type={Types.white}
          />
        );
      })}
    </>
  );
};
