import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import LinkButton, { Types, Sizes } from '../../components/LinkButton';
import { prefectures } from '../../constants/address';

export default () => {
  const data = useStaticQuery(graphql`
    query areaButtons {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { templateKey: { eq: "shrine-post" } } }
      ) {
        edges {
          node {
            excerpt(pruneLength: 400)
            id
            fields {
              slug
            }
            frontmatter {
              title
              area
              name
            }
          }
        }
      }
    }
  `);

  let areas = data.allMarkdownRemark.edges.map(item => {
    const targetArea = prefectures.find(
      prefecture => prefecture.id === item.node.frontmatter.area
    );
    return {
      id: item.node.frontmatter.area,
      label: targetArea.name,
      slug: item.node.fields.slug,
    };
  });

  areas = areas.filter((x, i, self) => {
    return (
      self.findIndex(item => {
        return item.id === x.id;
      }) === i
    );
  });

  return (
    <>
      {areas.map((item, i) => {
        return (
          <LinkButton
            key={i}
            to={`/areas/${item.id}`}
            label={item.label}
            size={Sizes.middle}
            type={Types.white}
          />
        );
      })}
    </>
  );
};
