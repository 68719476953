import _ from 'lodash';
import React, { useContext } from 'react';
import { navigate } from '@reach/router';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { Store } from '../../store';
import { prefectures } from '../../constants/address';
import './style.sass';

export default () => {
  const { state } = useContext(Store);

  const handleSelectChange = e => {
    if (!e.currentTarget.value) {
      return;
    }
    navigate(e.currentTarget.value);
  };

  const getStyle = () => {
    let style = 'drawer';
    if (state.isDrawer) {
      style = `${style} drawer--active`;
    }
    return style;
  };

  const shrinePostData = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "shrine-post" } } }
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                shrines
                tags
                area
                name
                blessings
              }
            }
          }
        }
      }
    `
  );

  let areaOptions = [];
  let blessingOptions = [];
  let shrineOptions = [];

  shrinePostData.allMarkdownRemark.edges.forEach(item => {
    const { area, name, blessings } = item.node.frontmatter;
    const { slug } = item.node.fields;
    areaOptions.push(prefectures.find(prefecture => prefecture.id === area));
    shrineOptions.push({
      id: slug,
      name: name,
    });
    blessingOptions = [...blessingOptions, ...blessings];
  });

  areaOptions = areaOptions.filter((x, i, self) => {
    return (
      self.findIndex(item => {
        return item.id === x.id;
      }) === i
    );
  });

  blessingOptions = _.uniq(blessingOptions);

  return (
    <div className={getStyle()}>
      <div className="drawer__inner">
        <div className="drawer__head">神社を探す</div>
        <div className="drawer__body">
          <div className="drawer__item">
            <div className="drawer__label">ご利益から探す</div>
            <div className="form__selectContainer">
              <select className="form__select" onChange={handleSelectChange}>
                <option key={`blessing_empty`} value="">
                  -
                </option>
                {blessingOptions.map((item, i) => {
                  return (
                    <option key={`blessing_${i}`} value={`/blessings/${item}`}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="drawer__item">
            <div className="drawer__label">都道府県から探す</div>
            <div className="form__selectContainer">
              <select className="form__select" onChange={handleSelectChange}>
                <option key={`area_empty`} value="">
                  -
                </option>
                {areaOptions.map((item, i) => (
                  <option key={`area_${i}`} value={`/areas/${item.id}`}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="drawer__item">
            <div className="drawer__label">神社名から探す</div>
            <div className="form__selectContainer">
              <select className="form__select" onChange={handleSelectChange}>
                <option key={`shrine_empty`} value="">
                  -
                </option>
                {shrineOptions.map((item, i) => (
                  <option key={`shrine_${i}`} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="drawer__tail">
          <Link to="/company">会社概要</Link>
          <span> | </span>
          {/* <Link to="/contact">お問い合わせ</Link> */}
          <a href="mailto:info@hinata-bi.com">お問い合わせ</a>
        </div>
      </div>
    </div>
  );
};
