import React, { useReducer } from 'react';
import reducer from './reducer';

const ActionTypes = {
  IS_DRAWER: 'IS_DRAWER',
};

const initialState = {
  isDrawer: false,
};

const Store = React.createContext();

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <Store.Provider value={{ state, dispatch }}>{children}</Store.Provider>
  );
};

export { ActionTypes, Store, Provider };
