import React from 'react';
import { Link } from 'gatsby';
import LinkIcon, {
  LinkIconTypes,
  LinkIconSizes,
} from '../../components/LinkIcon';
import ShrineButtons from './ShrineButtons';
import AreaButtons from './AreaButtons';
import TagButtons from './TagButtons';
import './style.sass';

export default () => {
  return (
    <footer className="footer">
      <div className="footer__header"></div>
      <div className="footer__body">
        <div className="footer__bodyInner">
          <h4 className="footer__title">ご利益別に神社を探す</h4>
          <nav className="footer__icons">
            <LinkIcon
              to="/blessings/縁切り"
              size={LinkIconSizes.middle}
              type={LinkIconTypes.enkiri}
            />
            <LinkIcon
              to="/blessings/縁結び"
              size={LinkIconSizes.middle}
              type={LinkIconTypes.enmusubi}
            />
            <LinkIcon
              to="/blessings/家内安全"
              size={LinkIconSizes.middle}
              type={LinkIconTypes.kanaianzen}
            />
            <LinkIcon
              to="/blessings/仕事金運"
              size={LinkIconSizes.middle}
              type={LinkIconTypes.shigotokiun}
            />
            <LinkIcon
              to="/blessings/厄除け"
              size={LinkIconSizes.middle}
              type={LinkIconTypes.yakuyoke}
            />
            <LinkIcon
              to="/blessings"
              size={LinkIconSizes.middle}
              type={LinkIconTypes.sonota}
            />
          </nav>
          <h4 className="footer__title">記事一覧</h4>
          <div className="footer__label">カテゴリ別</div>
          <nav className="footer__buttons">
            <TagButtons />
          </nav>
          <div className="footer__label">都道府県別</div>
          <nav className="footer__buttons">
            <AreaButtons />
          </nav>
          <div className="footer__label">神社別</div>
          <nav className="footer__buttons">
            <ShrineButtons />
          </nav>
        </div>

        <div className="footer__bottom">
          <nav className="footer__bottomLinks">
            <Link className="footer__bottomLink" to={'/company'}>
              会社概要
            </Link>
            {/* <span>|</span>
            <Link className="footer__bottomLink" to={'/contact'}>
              お問い合わせ
            </Link> */}
            <a className="footer__bottomLink" href="mailto:info@hinata-bi.com">
              お問い合わせ
            </a>
          </nav>
          <div className="footer__cp">＠ヒナタビ</div>
        </div>
      </div>
    </footer>
  );
};
