import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import LinkButton, { Types, Sizes } from '../../components/LinkButton';

export default () => {
  const data = useStaticQuery(graphql`
    query shrineButtons {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: {
          frontmatter: {
            templateKey: { eq: "shrine-post" }
            featuredpost: { eq: true }
          }
        }
      ) {
        edges {
          node {
            excerpt(pruneLength: 400)
            id
            fields {
              slug
            }
            frontmatter {
              title
              area
              name
            }
          }
        }
      }
    }
  `);

  return (
    <>
      {data.allMarkdownRemark.edges.map((item, i) => {
        return (
          <LinkButton
            key={i}
            to={item.node.fields.slug}
            label={item.node.frontmatter.name}
            size={Sizes.middle}
            type={Types.white}
          />
        );
      })}
    </>
  );
};
