import React, { useContext } from 'react';
import { Store, ActionTypes } from '../../store';
import Logo, { Sizes } from '../Logo';
import { Link } from 'gatsby';
import './style.sass';

const Header = () => {
  const { state, dispatch } = useContext(Store);

  const handleToggleButton = () => {
    dispatch({ type: ActionTypes.IS_DRAWER, value: !state.isDrawer });
  };

  const getStyle = () => {
    let style = 'header__toggleButtonInner';
    if (state.isDrawer) {
      style = `${style} header__toggleButtonInner--active`;
    }
    return style;
  };

  return (
    <>
      <header className="header">
        <nav className="header__nav">
          <Link className="header__navItem" to="/">
            TOP
          </Link>
          <Link className="header__navItem" to="/#map">
            神社を探す
          </Link>
          <Link to="/">
            <Logo size={Sizes.middle} />
          </Link>
          <Link className="header__navItem" to="/company">
            会社概要
          </Link>
          {/* <Link className="header__navItem" to="/contact">
            お問い合わせ
          </Link> */}
          <a className="header__navItem" href="mailto:info@hinata-bi.com">
            お問い合わせ
          </a>
        </nav>
        <div className="header__toggleButton" onClick={handleToggleButton}>
          <div className={getStyle()} />
        </div>
      </header>
    </>
  );
};
export default Header;
