import { ActionTypes } from './store';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.IS_DRAWER:
      return {
        ...state,
        isDrawer: action.value,
      };

    default:
      return new Error();
  }
};

export default reducer;
