import React from 'react';
import { Link } from 'gatsby';
import './style.sass';

export const Types = {
  white: 'white',
};

export const Sizes = {
  large: 'large',
  middle: 'middle',
  small: 'small',
};

const getClasses = (type, size) => {
  return `linkButton linkButton--${type} linkButton--${size}`;
};

const LinkButton = (props = { type: 'white', size: 'middle' }) => {
  return (
    <Link className={getClasses(props.type, props.size)} to={props.to}>
      {props.label}
    </Link>
  );
};

export default LinkButton;
