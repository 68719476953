import React, { useState, useEffect } from 'react';
import { Provider } from '../store';
import { isAdmin } from '../firebase';
import { getUser } from '../utils';
import constants from '../constants';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Drawer from '../components/Drawer';

import './all.sass';

export default ({ children }) => {
  const [isRead, setIsRead] = useState(false);
  useEffect(() => {
    console.log(window.location.hostname, constants.host.development);
    if (window.location.hostname === constants.host.development) {
      isAdmin(getUser().uid)
        .then(res => {
          return setIsRead(res);
        })
        .catch(error => console.log(error));
    } else {
      setIsRead(true);
    }
  }, []);
  return (
    <>
      {isRead ? (
        <Provider>
          <Header />
          <main>{children}</main>
          <Footer />
          <Drawer />
        </Provider>
      ) : null}
    </>
  );
};
