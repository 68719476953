import { prefectures, areas } from './address';
const constants = {
  prefectures,
  areas,
  articleTypes: {
    new: 'new',
    area: 'area',
    shrine: 'shrine',
    tag: 'tag',
    blessing: 'blessings',
  },
  basePath: {
    blog: '/blog',
    blogArea: '/blog-area',
    blogTag: '/blog-tag',
    blogShrine: '/blog-shrine',
    blogBlessings: '/blog-blessings',
    shrine: '/shrine',
    shrineArea: '/shrine-area',
    shrineTag: '/shrine-tag',
    shrineBlessings: '/shrine-blessings',
    tags: '/tags',
    products: '/products',
    areas: '/areas',
    blessings: '/blessings',
  },
  siteName: 'ヒナタビ',
  host: {
    local: 'localhost',
    development: 'develop--hinatabi.netlify.app',
    production: 'hinata-bi.com',
  },
};

export default constants;
