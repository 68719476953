import React from 'react';
import Image from '../Image';
import { Link } from 'gatsby';
import './style.sass';

export const LinkIconTypes = {
  enkiri: 'enkiri',
  enmusubi: 'enmusubi',
  kanaianzen: 'kanaianzen',
  shigotokiun: 'shigotokiun',
  sonota: 'sonota',
  yakuyoke: 'yakuyoke',
  oinori: 'oinori',
  omamori: 'omamori',
  omikuzi: 'omikuzi',
};

export const LinkIconSizes = {
  large: 'large',
  middle: 'middle',
  small: 'small',
};

const sources = {
  enkiri: { src: 'components/icons/icon_icon_enkiri.png', alt: '' },
  enmusubi: { src: 'components/icons/icon_icon_enmusubi.png', alt: '' },
  kanaianzen: { src: 'components/icons/icon_icon_kanaianzen.png', alt: '' },
  shigotokiun: { src: 'components/icons/icon_icon_shigotokinun.png', alt: '' },
  sonota: { src: 'components/icons/icon_icon_sonota.png', alt: '' },
  yakuyoke: { src: 'components/icons/icon_icon_yakuyoke.png', alt: '' },
  oinori: { src: 'components/icons/icon_oinori_on.png', alt: '' },
  omamori: { src: 'components/icons/icon_omamori_on.png', alt: '' },
  omikuzi: { src: 'components/icons/icon_omikuzi_on.png', alt: '' },
};

const getClasses = size => {
  return `linkIcon linkIcon--${size}`;
};

const LinkIcon = (props = { type: 'enkiri', size: 'middle' }) => {
  const filename = sources[props.type].src;
  return (
    <Link className={getClasses(props.size)} to={props.to}>
      <Image filename={filename} />
    </Link>
  );
};

export default LinkIcon;
